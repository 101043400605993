import { createEntityAdapter, createSlice, EntityState, PayloadAction, createSelector } from '@reduxjs/toolkit';
import Activity from '../../models/activity.model';
import { RootState } from '../../store';
import { ACTIVITY_STATE } from '../../../helpers/planning';
import {
    canModify,
    selectIsMultiJobUser,
    selectIsUserJob,
} from './planning.slice';
import { ALL_JOBS } from '../../../constants/Jobs';

const activitiesAdapter = createEntityAdapter({
    selectId: (activity: Activity) => activity.id,
});

const baselineAdapter = createEntityAdapter({
    selectId: (activity: Activity) => activity.identity,
});

export type ActivitySliceState = {
    activitiesDictionary: EntityState<Activity, number>;
    baseLineDictionary: EntityState<Activity, string>;
    filteredActivitiesCount: number;
    activityBroadcastData: {
        id: number;
        isTaskExist: boolean;
        context: string;
        isNewTask: boolean;
        updatedData: any;
        diffFields: string[];
    } | null;
};

const initialState: ActivitySliceState = {
    activitiesDictionary: activitiesAdapter.getInitialState(),
    baseLineDictionary: baselineAdapter.getInitialState(),
    filteredActivitiesCount: 0,
    activityBroadcastData: null,
};

const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        setActivities: (state, action: PayloadAction<Activity[]>) => {
            activitiesAdapter.setAll(state.activitiesDictionary, action.payload);
        },
        updateActivity: (state, action: PayloadAction<Activity>) => {
            activitiesAdapter.upsertOne(state.activitiesDictionary, action.payload);
        },
        setBaselineActivities: (state, action: PayloadAction<Activity[]>) => {
            baselineAdapter.setAll(state.baseLineDictionary, action.payload);
        },
        updateBaselineActivity: (state, action: PayloadAction<Activity>) => {
            baselineAdapter.upsertOne(state.baseLineDictionary, action.payload);
        },
        removeAllActivities: (state) => {
            activitiesAdapter.removeAll(state.activitiesDictionary);
        },
        removeAllBaselineActivities: (state) => {
            baselineAdapter.removeAll(state.baseLineDictionary);
        },
        removeActivity: (state, action: PayloadAction<number>) => {
            activitiesAdapter.removeOne(state.activitiesDictionary, action.payload);
        },
        setFilteredActivitiesCount: (state, action: PayloadAction<number>) => {
            state.filteredActivitiesCount = action.payload;
        },
        setActivityBroadcastData: (state, action: PayloadAction<ActivitySliceState['activityBroadcastData']>) => {
            state.activityBroadcastData = action.payload;
        },
        clearActivityBroadcastData: (state) => {
            state.activityBroadcastData = null;
        },
        incrementFilteredActivitiesCount: (state) => {
            state.filteredActivitiesCount += 1;
        }
        // Add more reducers as needed
    },
});

export const {
    setActivities,
    updateActivity,
    setBaselineActivities,
    updateBaselineActivity,
    removeAllActivities,
    removeAllBaselineActivities,
    removeActivity,
    setFilteredActivitiesCount,
    setActivityBroadcastData,
    clearActivityBroadcastData,
    incrementFilteredActivitiesCount,
} = activitySlice.actions;

// Selectors
export const {
    selectAll: selectAllActivities,
    selectById: selectActivityById,
    selectIds: selectActivityIds,
    selectEntities: selectActivityEntities, // Returns all entities as a dictionary
} = activitiesAdapter.getSelectors((state: RootState) => state.activity.activitiesDictionary);

export const {
    selectAll: selectAllBaselineActivities,
    selectById: selectBaselineActivityById,
    selectIds: selectBaselineActivityIds,
    selectEntities: selectBaselineActivityEntities, // Returns all entities as a dictionary
} = baselineAdapter.getSelectors((state: RootState) => state.activity.baseLineDictionary);

export const selectFilteredActivitiesCount = (state: RootState) => state.activity.filteredActivitiesCount;

// Individual selectors
const selectPlanningSelectedState = (state: RootState) => state.planning.planningSelected;
const selectModeSelectedState = (state: RootState) => state.planning.modeSelected;
const selectCanModifyState = (state: RootState) => canModify(state);
const selectIsUserJobState = (state: RootState) => selectIsUserJob(state);
const selectIsMultiJobUserState = (state: RootState) => selectIsMultiJobUser(state);

// Memoized selector for activity editing permissions
export const makeCanEditActivity = createSelector(
    [
        selectPlanningSelectedState,
        selectModeSelectedState,
        selectCanModifyState,
        selectIsUserJobState,
        selectIsMultiJobUserState,
    ],
    (planningSelected, modeSelected, canModifyState, isUserJobState, isMultiJobUserState) =>
        (id: string | number, status: string, jobId: string[]) => {
            const isProject = +id === planningSelected.rootActivityId;
            const archived = modeSelected === 'archive';
            const validated = status !== ACTIVITY_STATE.UNVALID;
            const noWriteAccess = !canModifyState;
            const isMultiJobActivity = (jobId || []).filter((job) => job === ALL_JOBS).length > 0;
            const noJobRule = !isProject && !isUserJobState(jobId) && !isMultiJobUserState && !isMultiJobActivity;

            return !(archived || validated || noWriteAccess || noJobRule);
        }
);

// Convenience selector for components
export const canEditAnActivity = (state: RootState) => makeCanEditActivity(state);

export const selectActivityBroadcastData = (state: RootState) => state.activity.activityBroadcastData;

export default activitySlice.reducer;
