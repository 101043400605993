import * as Sentry from '@sentry/browser';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { clone, intersection } from 'lodash';
import { Gantt as dxhtmlGantt } from 'dhtmlx-gantt';
import { Button, Popconfirm } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ActivityService from '../../services/activity.service';
import { PlanningContext } from '../../contexts/app/PlanningContext';
import DrawerActivity from './DrawerActivity';
import { notificationError, requestError } from '../../helpers/notification';
import { formatGanttTask, generateLinkNodes, ACTIVITY_STATE } from '../../helpers/planning';
import GanttToolbar from './GanttToolbar';
import TaskStripes from '../../helpers/task-stripes';
import GanttConfigurations, { GANTT_CUSTOM_PROJECT } from './gantt_config';
import MultipleActivityEdit from './drawer/MultipleActivityEdit';
import GanttEvents, { saveScrollState } from './gantt_events';
import GanttTaskLayers from './gantt_layers';
import MultipleLinkCreation from './drawer/MultipleLinkCreation';
import MultipleActivityDuplication from './drawer/MultipleActivityDuplication';
import { generateString } from '../../helpers/string-helper';
import { ALL_JOBS, JOB_FIELD } from '../../constants/Jobs';
import { getArrayInListByCondition } from '../../helpers/tree-helper';
import { NeedsContext } from '../../contexts/needs/NeedsContext';
import { PlanningNeedsContext } from '../../contexts/needs/PlanningNeedsContext';
import { getTextColor } from '../../helpers/text-color';
import TasksStripesCSS from './gantt-styled-component/TaskStripes';
import LinkBackgroundCSS from './gantt-styled-component/LinkBackground';
import CriticalTaskStyle from './gantt-styled-component/CriticalTaskStyle';
import GridHeaderColor from './gantt-styled-component/GridHeaderColor';
import SimpleTaskColor from './gantt-styled-component/SimpleTaskColor';
import TaskLevelColor from './gantt-styled-component/TaskLevelColor';
import TaskResourceColor from './gantt-styled-component/TaskResourceColor';
import MarkerStyle from './gantt-styled-component/MarkerStyle';
import GanttWrapper from './gantt-styled-component/GanttWrapper';
import {
    incrementRefreshGantt,
    incrementReRenderGantt,
    selectGanttData,
    setProcessingGantt,
    setSpinProgress,
    setTasksStripesStyles,
} from '../../redux/slices/app/planning.slice';
import {
    generateFilteredFlattenActivities,
    updateActivitiesDictionary,
} from '../../redux/thunks/activity-slice.thunk';
import { selectActiveTabFilterData, selectTabPreferences, updateTabPreferences } from '../../redux/slices/app/tab.slice';
import { selectUserPreferences } from '../../redux/slices/app/user.slice';
import updateGanttColumns from './gantt_columns';
import ganttTemplates from './gantt_templates';
import { addVirtualTasks as addGanttVirtualTasks } from './gantt_utils';
import fetchCalendars from '../../redux/thunks/calendar-slice.thunk';
import { refreshPlanningSelected } from '../../redux/thunks/planning-slice.thunk';
import { removeActivity, setFilteredActivitiesCount } from '../../redux/slices/app/activity.slice';
import computeActivityCalendarSliceDictionary from '../../redux/thunks/activity-calendar-slice.thunk';
import { selectAllLinks, selectLinksStyles } from '../../redux/slices/app/link.slice';

const { showTaskActions, addGanttEvents } = GanttEvents;
const { setGanttConfigurations } = GanttConfigurations;

window.ganttInstance = null;
window.selectedSimpleTasks = [];
// used for disabling saving scroll to preference (printing)
window.saveScrollState = true;
// used for disabling gantt data processor event
window.ganttDataProcessor = true;

const Gantt = () => {
    let ganttContainer = useRef(null);
    const dispatch = useDispatch();
    const [taskDrawerVisible, setTaskDrawerVisible] = useState(false);
    const [taskDrawerTab, setTaskDrawerTab] = useState('activity_details');
    const [multiTaskBannerVisible, setMultiTaskBannerVisible] = useState(false);
    const [canDuplicateSelection, setCanDuplicateSelection] = useState(false);
    const [updateMultipleActivityActionComponent, setUpdateMultipleActivityActionComponent] = useState(0);
    const [multiTaskIds, setMultiTaskIds] = useState([]);
    const linksStyles = useSelector(selectLinksStyles);
    const [selectedTask, setSelectedTask] = useState(null);
    const {
        refreshGantt,
        loadingGantt,
        planningSelected,
        planningCustomFields,
        modeSelected,
        tasksStripesStyles,
        timeUnits,
        reRenderGantt,
        isUserJob,
        isMultiJobUser,
        canAccess,
        activitiesDictionary,
    } = useSelector(selectGanttData);
    const { updateActivityByBroadcast } = useContext(PlanningContext);
    const tabPreferences = useSelector(selectTabPreferences);
    const activeTabFilterData = useSelector(selectActiveTabFilterData);
    const userPreferences = useSelector(selectUserPreferences);
    const globalNeedList = useContext(NeedsContext).NeedList;
    const planningNeedList = useContext(PlanningNeedsContext).NeedList;
    const { i18n, t } = useTranslation();
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const addVirtualTasks = () => addGanttVirtualTasks(tabPreferences);
    const links = useSelector(selectAllLinks);

    // loading dhtmlx api for import and export
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '/dhtmlx_api.js';
        script.async = true;
        script.id = 'api_script';
        document.body.appendChild(script);
        return () => {
            const element = document.getElementById('api_script');
            element.parentNode.removeChild(element);
        };
    }, []);

    const hasBaseLine = tabPreferences?.gantt_baseline?.showBaseLine;

    const openTaskDrawer = (taskId, tab) => {
        const task = window.ganttInstance.getTask(taskId);

        if (task.$new) {
            task.identity = null;
            task.description = null;
            task.jobId = null;
        }
        setTaskDrawerTab(tab);
        setSelectedTask(task);
        setTaskDrawerVisible(true);
    };

    const closeTaskDrawer = () => {
        setSelectedTask((oldVal) => {
            const selectedTaskId = oldVal.id;
            if (window.ganttInstance.isTaskExists(selectedTaskId)) {
                window.ganttInstance.showTask(selectedTaskId);
            }
            return null;
        });
        setTaskDrawerVisible(false);
    };

    const addNewTask = async (taskId, isDragged = false, index, openEditor = true) => {
        window.saveScrollState = false;
        window.saveScrollState = false;
        const task = clone(window.ganttInstance.getTask(taskId));
        dispatch(setProcessingGantt(true));
        // const job = userJobsWithoutMulti()[0];
        const newTaskObject = {
            activityParentId: window.ganttInstance.getTask(task.parent)?.serverId,
            name: task.text,
            // job: [job],
        };
        // assigning default jobs
        const defaultJobsId = userPreferences?.planning_default_jobs?.[planningSelected?.id] || [];
        if (defaultJobsId.length > 0) {
            const jobCustomField = planningCustomFields.find((i) => i.name === JOB_FIELD);
            const jobCustomFieldChoicesObj = Object.values(jobCustomField.type.choices);
            const jobCustomFieldChoices = Object.keys(jobCustomField.type.choices);

            const jobsToAssign = defaultJobsId.flatMap((jobId) => {
                const jobObjIndex = jobCustomFieldChoicesObj.findIndex((job) => job.id === jobId);
                if (jobObjIndex !== -1) {
                    return jobCustomFieldChoices[jobObjIndex];
                }
                return [];
            });

            if (jobsToAssign.length > 0) {
                newTaskObject.job = jobsToAssign;
            }
        }
        if (isDragged) {
            newTaskObject.startDate = moment(task.start_date).utc(true).valueOf();
            newTaskObject.endDate = moment(task.end_date).utc(true).valueOf();
        }
        let activity = null;
        const activityUpdated = await ActivityService.createActivity(newTaskObject).catch((error) => {
            if (window.ganttInstance.isTaskExists(taskId)) {
                window.ganttInstance.deleteTask(taskId);
            }
            requestError(error, `Erreur lors la création de l'activité`);
            Sentry.captureException(error);
        });
        // refetch
        // const activityUpdated = await ActivityService.showActivity(newActivity.id)
        console.log('🚀 ~ addNewTask ~ activityUpdated:', activityUpdated);
        if (window.ganttInstance.isTaskExists(taskId)) {
            window.ganttInstance.deleteTask(taskId);
        }
        dispatch(
            updateActivitiesDictionary(activityUpdated.id, {
                ...activityUpdated,
                baseLineStartDate: null,
                baseLineEndDate: null,
                baseLineProgress: 0,
            })
        );
        const durationUnit = timeUnits.find((timeUnit) => timeUnit.id === Number(activityUpdated.dayDefinitionId));
        console.log('adding task');
        window.ganttInstance.unselectTask();
        const taskColor = tabPreferences.gantt_parameters?.simpleTaskBarColor;

        const newId = window.ganttInstance.addTask(
            formatGanttTask(activityUpdated, {
                durationApi: Number(activityUpdated.duration / durationUnit.duration),
                realChildren: [],
                color: taskColor,
                parent: task.parent,
            }),
            undefined,
            index ?? null
        );
        console.log('refreshing', newId);
        // focus on name
        setTimeout(() => {
            // window.ganttInstance.refreshData();
            window.ganttInstance.render();
            window.ganttInstance.showTask(newId);
            window.ganttInstance.selectTask(newId); 
            if (openEditor) {
                console.log('focus on name');
                window.ganttInstance.ext.inlineEditors.startEdit(newId, 'text');
            }
            window.saveScrollState = false;
            dispatch(setProcessingGantt(false));
        }, 100);
        activity = activityUpdated;
        return new Promise((resolve) => {
            resolve(activity);
        });
    };

    const ganttConfigure = (ganttColumnsPreferences) => {
        // eslint-disable-next-line consistent-return
        window.ganttInstance.createDataProcessor(async (entity, action, data) => {
            // const isGroupement =
            //     userPreferences?.filtered_group?.[planningSelected.rootActivityId]?.groupingType === 'custom_fields';
            // entity - "task"|"link"
            // action - "create"|"update"|"delete"
            // data - an object with task or link data
            if (!data.no_update && window.ganttDataProcessor) {
                switch (entity) {
                    case 'link':
                        switch (action) {
                            case 'create': {
                                // eslint-disable-next-line no-case-declarations
                                const sourceActivity = window.ganttInstance.getTask(data.source);
                                // eslint-disable-next-line no-case-declarations
                                const targetActivity = window.ganttInstance.getTask(data.target);
                                try {
                                    dispatch(setProcessingGantt(true));
                                    
                                    await ActivityService.createLink(
                                        sourceActivity.serverId,
                                        targetActivity.serverId,
                                        0,
                                        ['FD', 'DD', 'FF', 'DF'][parseInt(data.type, 10)],
                                        sourceActivity.dayDefinitionId
                                    ).then(() => 
                                        window.ganttInstance.deleteLink(data.id)
                                    );
                                    dispatch(setProcessingGantt(false));
                                } catch (error) {
                                    window.ganttInstance.getLink(data.id).is_virtual = true;
                                    window.ganttInstance.deleteLink(data.id);
                                    Sentry.captureException(error);
                                    requestError(error, 'Erreur lors de la création du lien');
                                    dispatch(setProcessingGantt(false));
                                }
                                break;
                            }

                            case 'delete':
                                if (!data.is_virtual) {
                                    try {
                                        dispatch(setProcessingGantt(true));
                                        if (
                                            window.ganttInstance.isTaskExists(data.source) &&
                                            window.ganttInstance.isTaskExists(data.target)
                                        ) {
                                            await ActivityService.deleteLink(data.serverId);
                                        }
                                        dispatch(setProcessingGantt(false));
                                    } catch (error) {
                                        Sentry.captureException(error);
                                        if (error?.response?.data?.type === 'forbiden_job_operation') {
                                            notificationError(
                                                'Erreur lors de la suppression du lien',
                                                'Vous n’avez pas les droits pour supprimer le lien concerné.'
                                            );
                                        } else {
                                            requestError(error, 'Erreur lors de la suppression du lien');
                                        }
                                    }
                                }
                                break;
                            default:
                        }
                        break;
                    default:
                }
            }
        });
        updateGanttColumns(ganttColumnsPreferences);
        // console.log('🚀 ~ file: Gantt:1150 ~ ganttConfigure ~ tabPreferences', tabPreferences);
        setGanttConfigurations(tabPreferences, planningSelected, modeSelected, canAccess, generalTranslation);
        // create on drag
        const onDragEnd = async (startPoint, endPoint, startDate, endDate, tasksBetweenDates, tasksInRow) => {
            if (tasksInRow.length === 1) {
                const currentTask = tasksInRow[0];
                if (window.ganttInstance.config.readonly) {
                    notificationError(t('gantt_events.new_activity'), t('general.operation_not_permitted'));
                    return;
                }
                if (currentTask.status !== ACTIVITY_STATE.UNVALID) {
                    notificationError(t('gantt_events.new_activity'), t('general.operation_not_permitted'));
                    return;
                }
                if (+currentTask.serverId === planningSelected.rootActivityId) {
                    notificationError(t('gantt_events.new_activity'), t('general.operation_not_permitted'));
                    return;
                }
                if (currentTask.isExtra) {
                    return;
                }
                if (currentTask.type === window.ganttInstance.config.types.customProject) {
                    const newTaskId = window.ganttInstance.addTask(
                        {
                            text: t('gantt_events.new_activity'),
                            start_date: startDate,
                            end_date: endDate,
                        },
                        currentTask.id
                    );
                    await addNewTask(newTaskId, true);
                } else {
                    // on ajoute la récap
                    // on place la récap à la position de la tache actuelle
                    // on déplace la tache actuelle dans la récap
                    // on ajoute la nouvelle tache dans la récap
                    const prevTaskId = window.ganttInstance.getPrevSibling(currentTask.id);
                    const projectName = t('gantt_events.new_summary_task');
                    const newProjectId = window.ganttInstance.addTask(
                        {
                            text: projectName,
                            // render: ganttParameters?.splitTask ? 'split' : '',
                            type: window.ganttInstance.config.types.customProject,
                        },
                        currentTask.parent
                    );
                    const projectIndex = window.ganttInstance.getTaskIndex(currentTask.id);
                    const newProjectTask = await addNewTask(newProjectId, false, projectIndex, false);
                    const projectTask = window.ganttInstance.getTaskByServerId(newProjectTask.id);
                    dispatch(setProcessingGantt(true));
                    if (prevTaskId) {
                        const prevTask = window.ganttInstance.getTask(prevTaskId);
                        await ActivityService.moveAfter(prevTask.serverId, [newProjectTask.id]);
                    }
                    // SHOULD DO MOVE AFTER WHEN NO PREV TASK TO PLACE RECAP ON TOP
                    await ActivityService.updateActivityParent(newProjectTask.id, [currentTask.serverId]);
                    currentTask.parent = projectTask.id;
                    window.ganttInstance.refreshTask(currentTask.id);
                    dispatch(setProcessingGantt(false));
                    const newTaskId = window.ganttInstance.addTask(
                        {
                            text: t('gantt_events.new_activity'),
                            start_date: startDate,
                            end_date: endDate,
                        },
                        projectTask.id
                    );
                    const newChildTask = await addNewTask(newTaskId, true);
                    // window.ganttInstance.calculateTaskLevel(window.ganttInstance.getTask(newTask.id));
                    if (projectTask.render !== 'split') {
                        const newChildTaskObj = window.ganttInstance.getTaskByServerId(newChildTask.id);

                        window.ganttInstance.open(projectTask.id);
                        window.ganttInstance.ext.inlineEditors.startEdit(newChildTaskObj.id, 'text');
                    }
                }
            } else if (tasksInRow.length === 0) {
                // should never happened
                window.ganttInstance.createTask({
                    text: t('gantt_events.new_activity'),
                    start_date: window.ganttInstance.roundDate(startDate),
                    end_date: window.ganttInstance.roundDate(endDate),
                });
            }
        };
        // Active le plugin pour les tooltips.
        window.ganttInstance.plugins({
            tooltip: true,
        });
        // Desactive l'affichage par defaut sur les taches de la table.
        window.ganttInstance.templates.tooltip_text = () => null;
        // Active la création d'activité par ctrl+click sur un espace vide de la timeline.
        window.ganttInstance.config.click_drag = {
            callback: onDragEnd,
            useKey: 'ctrlKey',
            singleRow: true,
        };
        // Active le deplacement dans le gantt par drag sur un espace vide de la timeline.
        window.ganttInstance.config.drag_timeline = {
            ignore: '.gantt_task_line, .gantt_task_link',
            useKey: false,
        };
        // Active un tooltip sur l'espace vide de la timeline pour rappeler la possibilité de faire ctrl+click pour creer une activite.
        window.ganttInstance.ext.tooltips.tooltipFor({
            selector: '.gantt_task_cell',
            html(event) {
                if (canAccess && modeSelected !== 'archive') {
                    const positionX = event.target.offsetLeft + event.offsetX;
                    const date = window.ganttInstance.dateFromPos(positionX);
                    const taskId = event.target.parentNode.attributes.task_id.nodeValue;
                    if (window.ganttInstance.isTaskExists(taskId)) {
                        const task = window.ganttInstance.getTask(taskId);
                        const format = window.dateStringFormat ?? 'HH\\h MM\\m SS\\s \\a\\t DD/MM/YY';
                        return `${task.text} - ${moment(date).format(format)} <br/> ${t('gantt.create_activity')}`;
                    }
                }
                return false;
            },
        });
        ganttContainer.style.height = '100%';
        window.ganttInstance.init(ganttContainer);
        GanttTaskLayers.addGanttTaskLayers(tabPreferences, i18n);
    };

    const ganttLabellise = () => {
        window.ganttInstance.locale.labels.type_folder = 'Dossier';
        window.ganttInstance.locale.labels.type_planning = 'Planning';
        window.ganttInstance.locale.labels.type_job = JOB_FIELD;
    };

    const checkDuplicationState = useCallback(() => {
        const selectedTasks = window.ganttInstance.getSelectedTasks().map((i) => window.ganttInstance.getTask(i));
        if (selectedTasks.findIndex((task) => task.serverId === planningSelected.rootActivityId) !== -1) {
            setCanDuplicateSelection(false);
            return false;
        }
        let canDuplicate = true;
        // we only check child tasks because selected tasks is sorted from the top of the tree
        selectedTasks.every((task) => {
            if (!canDuplicate) return false;
            // we don't test simple tasks because they don't have children
            if (task.type === 'task') return true;
            const taskChildrenIds = [];
            let checkChildrenSelected = [];
            window.ganttInstance.eachTask((child) => taskChildrenIds.push(child.id), task.id);
            checkChildrenSelected = intersection(window.ganttInstance.getSelectedTasks(), taskChildrenIds);
            canDuplicate = checkChildrenSelected.length === 0;
            return true;
        });
        // console.log("🚀 ~ file: Gantt:1336 ~ checkDuplicationState ~ canDuplicate:", canDuplicate)
        setCanDuplicateSelection(canDuplicate);
        return canDuplicate;
    }, [canDuplicateSelection]);

    const ganttEvents = () => {
        addGanttEvents({
            tabPreferences,
            planningSelected,
            fetchCalendars,
            incrementReRenderGantt,
            incrementRefreshGantt,
            requestError,
            refreshPlanningSelected,
            updateActivitiesDictionary,
            removeActivity,
            updateTabPreferences,
            isUserJob,
            isMultiJobUser,
            canAccess,
            notificationError,
            addNewTask,
            openTaskDrawer,
            closeTaskDrawer,
            addVirtualTasks,
            timeUnits,
            hasBaseLine,
            i18n,
            updateActivityByBroadcast,
        });
        // we use global variable because of asynchronous setState in React that slowing the process
        window.ganttInstance.attachEvent('onTaskSelected', (id) => {
            const task = window.ganttInstance.getTask(id);
            if (task.type !== window.ganttInstance.config.types.customProject) {
                const currentTaskIndex = window.selectedSimpleTasks.findIndex((i) => i.serverId === task.serverId);
                if (currentTaskIndex === -1) {
                    window.selectedSimpleTasks.push(task);
                }
            }
        });
        window.ganttInstance.attachEvent('onTaskUnSelected', (id) => {
            if (window.ganttInstance.isTaskExists(id)) {
                const currentTaskIndex = window.selectedSimpleTasks.findIndex(
                    (i) => i.serverId === window.ganttInstance.getTask(id).serverId
                );
                // existing and being unselected
                if (currentTaskIndex !== -1) {
                    window.selectedSimpleTasks.splice(currentTaskIndex, 1);
                }
            }
        });
        window.ganttInstance.attachEvent('onMultiSelect', (e) => {
            const selectedTasks = window.ganttInstance.getSelectedTasks();
            if (selectedTasks.length > 1) {
                window.ganttInstance.ext.quickInfo.hide(true);
                setMultiTaskIds(selectedTasks.map((id) => window.ganttInstance.getTask(id).serverId));
                // if selecting with shift order will be from top to bottom
                if (e.shiftKey) {
                    // setSelectedSimpleTasks(
                    window.selectedSimpleTasks = selectedTasks
                        .map((i) => window.ganttInstance.getTask(i))
                        .filter((act) => act.type !== window.ganttInstance.config.types.customProject);
                    // );
                }
                setMultiTaskBannerVisible(true);
                setUpdateMultipleActivityActionComponent((val) => val + 1);
                checkDuplicationState();
            } else {
                setUpdateMultipleActivityActionComponent(0);
                setMultiTaskIds([]);
                setMultiTaskBannerVisible(false);
            }
            return true;
        });
        // window.ganttInstance.attachEvent('onBeforeMultiSelect', (e) => {
        //     if (e.shiftKey) {
        //         return false;
        //     }
        //     return true;
        // });
    };

    /**
     * Changing tab preferences
     * @param {*} dictionary Activities dictionary
     */
    const migrateTabPreferences = async (dictionary) => {
        let doRefresh = false;
        // MIGRATE TO USE IDENTITY
        if (!tabPreferences.gantt_split_tasks) {
            const splitTasks = (tabPreferences.gantt_split_task || []).map(
                (serverId) => dictionary[serverId]?.identity
            );
            console.log('🚀 AFTER MIGRATE SPLIT~ file: Gantt:1526 ~ .then ~ splitTasks:', splitTasks);
            if (splitTasks.length > 0) {
                await dispatch(updateTabPreferences({ gantt_split_tasks: splitTasks, gantt_split_task: ['UNUSED'] }));
                doRefresh = true;
            }
        }
        if (!tabPreferences.gantt_collapsed_tasks) {
            const collapsedTasks = (tabPreferences.gantt_collapsed_task || []).map(
                (serverId) => dictionary[serverId]?.identity
            );
            console.log('🚀 AFTER MIGRATE COLLAPSE~ file: Gantt:1526 ~ .then ~ splitTasks:', collapsedTasks);
            if (collapsedTasks.length > 0) {
                await dispatch(updateTabPreferences({ gantt_collapsed_tasks: collapsedTasks, gantt_collapsed_task: ['UNUSED'] }));
                doRefresh = true;
            }
        }
        if (doRefresh) {
            dispatch(refreshPlanningSelected());
        }
    };

    const removeAllGanttTooltips = () => {
        document.querySelectorAll('.gantt_tooltip').forEach((tooltip) => tooltip.remove());
    };

    const loadGantt = useCallback(async () => {
        dispatch(setProcessingGantt(true));
        const filteredActivities = await dispatch(generateFilteredFlattenActivities());
        dispatch(setFilteredActivitiesCount((filteredActivities.payload || []).filter((i) => !i.virtual).length));
        if (planningSelected && window.ganttInstance) {
            // remove all tooltips
            removeAllGanttTooltips();
            await migrateTabPreferences(activitiesDictionary);
            ganttTemplates();
            // window.ganttInstance.render();
            window.ganttInstance.config.initial_scroll = false;

            let flattenActivities = [...filteredActivities.payload];
            dispatch(
                setTasksStripesStyles(
                    flattenActivities
                        .filter((flattenActivity) => TaskStripes.hasStripes(flattenActivity))
                        .map((flattenActivity) => TaskStripes.formatForState(flattenActivity))
                )
            );
            // global parameter for split task

            const splitTasks = tabPreferences.gantt_split_tasks || [];
            // TODO optimize: formatGanttTask?
            flattenActivities = flattenActivities.map((i) => {
                let taskColor =
                    i.type === GANTT_CUSTOM_PROJECT
                        ? tabPreferences.gantt_parameters?.parentTaskBarColor
                        : tabPreferences.gantt_parameters?.simpleTaskBarColor;
                if (i.type === 'milestone') {
                    taskColor = tabPreferences.gantt_parameters?.milestoneColor;
                }
                return {
                    ...i,
                    color: taskColor,
                    text: i.virtual && i.text === ALL_JOBS ? generalTranslation('all_jobs') : i.text,
                    textColor: getTextColor(taskColor),
                    bar_height: hasBaseLine ? window.ganttInstance.config.bar_height / 2 : null,
                    render: splitTasks.indexOf(i.identity) !== -1 ? 'split' : '',
                };
            });

            // filtre sur les dates prioritaire
            if (hasBaseLine && activeTabFilterData.dateFilter) {
                window.ganttInstance.config.start_date = window.ganttInstance.date.add(
                    new Date(
                        Math.min(
                            flattenActivities[0].planned_start?.valueOf() || Number.MAX_VALUE,
                            flattenActivities[0].start_date.valueOf()
                        )
                    ),
                    -1,
                    'day'
                );
                window.ganttInstance.config.end_date = window.ganttInstance.date.add(
                    new Date(
                        Math.max(
                            flattenActivities[0].planned_end?.valueOf() || Number.MIN_VALUE,
                            flattenActivities[0].end_date.valueOf()
                        )
                    ),
                    1,
                    'day'
                );
            }
            // console.log(
            //     '🚀 ~ file: Gantt ~ line 1384 ~ ).then ~ flattenActivities',
            //     activitiesWithResources
            // );
            dispatch(setSpinProgress(80));

            const allLinks = generateLinkNodes(links, flattenActivities);
            // console.log('🚀 ~ file: Gantt ~ line 1392 ~ ).then ~ links', links);
            window.ganttInstance.silent(() => {
                console.log('SILENT');
                window.ganttInstance.clearAll();
            });
            dispatch(setSpinProgress(90));
            window.ganttInstance.parse({
                data: [...flattenActivities],
                links: JSON.parse(JSON.stringify(allLinks)),
            });
            console.log('🚀 ~ loadGantt ~ flattenActivities:', flattenActivities);
            // window.ganttInstance.init(ganttContainer);
        }
        // const ganttStartDates
        // collapse task preference
        const collapsedTasks = tabPreferences.gantt_collapsed_tasks || [];

        window.ganttInstance.eachTask((act) => {
            const task = act;
            task.$open = collapsedTasks.indexOf(task.identity) === -1;
            if (task.$open === false && window.ganttInstance.isSplitTask(task)) {
                window.ganttInstance.close(t.id);
            }
            // reselect task if multiselect before
            if (multiTaskIds.length > 0 && multiTaskIds.includes(task.serverId)) {
                window.ganttInstance.selectTask(task.id);
            }
        });

        // markers
        const markers = tabPreferences?.gantt_markers ?? [];
        markers
            .filter((i) => i.show && !i.markerStartDate)
            .forEach((marker) => {
                window.ganttInstance.addMarker({
                    start_date: moment(marker.markerDate),
                    css: marker.markerClass,
                    text: marker.markerName,
                    title: moment(marker.markerDate).format('YYYY-MM-DD'),
                });
            });
        // ajouter un espace au début et ala fin du gantt lorsqu'il y a des marqueurs ou on affiche la ligne rouge
        addVirtualTasks(tabPreferences);

        const sort = tabPreferences?.planning_sort;
        if (sort && window.ganttInstance.config.columns.find((c) => c.name === sort.field)) {
            window.ganttInstance.sort(sort.field, sort.isDesc);
            // eslint-disable-next-line no-underscore-dangle
            window.ganttInstance._sort = {
                name: sort.field,
                direction: sort.isDesc ? 'asc' : 'desc',
            };
        }

        window.ganttInstance.render();
        dispatch(setProcessingGantt(false));
        await dispatch(computeActivityCalendarSliceDictionary());
    }, [multiTaskIds, activitiesDictionary]);

    // const reloadGantt = useCallback(
    //     async (caller) => {
    //         dispatch(setProcessingGantt(true));
    //         console.log("🚀 ~ filteredFlattenActivities:", filteredFlattenActivities)
    //         await loadGantt(filteredFlattenActivities, caller);
    //     },
    //     [filteredFlattenActivities, activitiesDictionary]
    // );

    const onGanttRefresh = useCallback(async () => {
        await saveScrollState(updateTabPreferences);
        // const filtered = await generateFilteredFlattenActivities(activitiesDictionary, planningCustomFields);
        await loadGantt();
    }, [activitiesDictionary]);

    useEffect(() => {
        if (refreshGantt > 0) {
            onGanttRefresh();
        }
    }, [refreshGantt]);

    // used when updating no working days
    useEffect(() => {
        const onGanttRerender = async () => {
            window.ganttInstance?.render();
        };
        if (reRenderGantt > 0) {
            onGanttRerender();
        }
    }, [reRenderGantt]);

    // KEYBOARD SHORTCUTS
    // useHotkeys(
    //     'f6',
    //     async (e) => {
    //         e.preventDefault();
    //         setDisableReloading(async (current) => {
    //             if (!current) {
    //                 dispatch(setProcessingGantt(true));
    //                 await loadGantt(cloneActivityDictionary.current);
    //                 return true;
    //             }
    //             return current;
    //         });
    //     }
    //     // { filterPreventDefault: true }
    // );
    useHotkeys(
        'shift+w',
        async (e) => {
            e.preventDefault();
            showTaskActions();
        }
        // { filterPreventDefault: true }
    );

    useEffect(() => {
        console.log('LOADINGGANTT', loadingGantt)
        const onGanttLoading = async () => {
            try {
                if (ganttContainer && loadingGantt === false && planningSelected) {
                    console.log('ON INIT INSIDE');
                    dispatch(setProcessingGantt(true));

                    // if (window.ganttInstance) {
                    //     console.log('destroying 1')

                    //     window.ganttInstance.destructor();
                    //     gantt?.destructor();
                    // }

                    window.ganttInstance = dxhtmlGantt.getGanttInstance({ container: ganttContainer });
                    /* Sauvegarde de la préférence lors de changement de l’ordre des colonnes (Drag n Drop sur colonnes du Gantt) */
                    window.ganttInstance.attachEvent('onGanttReady', () => {
                        const grid = window.ganttInstance.$ui.getView('grid');
                        grid.attachEvent('onAfterColumnReorder', () => {
                            dispatch(updateTabPreferences({
                                gantt_columns_order: window.ganttInstance.config.columns.reduce((agg, el, index) => {
                                    const oldObject = { ...agg };
                                    // avoid falsy values, avoiding 0 value of order
                                    oldObject[el.id] = index + 1;
                                    return oldObject;
                                }, {}),
                            })).then(() => {
                                if (window.ganttInstance.config.columns[0].tree === false) {
                                    dispatch(refreshPlanningSelected());
                                }
                            });
                        });
                    });

                    ganttConfigure(tabPreferences?.gantt_columns || userPreferences?.default_gantt_columns || []);
                    ganttLabellise();
                    ganttEvents();
                    await loadGantt('ON INIT');
                    setTaskDrawerVisible(false);
                    console.log('ON INIT INSIDE 2');
                }
                if (loadingGantt && window.ganttInstance) {
                    saveScrollState(updateTabPreferences);
                }
            } catch (error) {
                console.log('cathed', error);
                requestError(error, 'Gantt');
                dispatch(setProcessingGantt(false));
            }
        };
        onGanttLoading();
        // return () => {
        //     if (window.ganttInstance) {
        //         console.log('destroying')
        //         window.ganttInstance.destructor();
        //         // gantt?.destructor();
        //     }
        // }
    }, [loadingGantt]);

    const handleDeleteMultipleActivity = async () => {
        try {
            await ActivityService.deleteMultipleActivity(multiTaskIds);
            // multiTaskIds.forEach((taskId) => window.ganttInstance.deleteTask(taskId));
            setMultiTaskIds([]);
            dispatch(refreshPlanningSelected(true));
        } catch (error) {
            requestError(error, t('gantt.delete_multiple'));
        }
    };

    return (
        <>
            {/* <Spin spinning={loadingGantt || processingGantt} size="large"> */}
            <TasksStripesCSS tasks={tasksStripesStyles} />
            <LinkBackgroundCSS linksStyles={linksStyles} />
            {tabPreferences?.gantt_parameters?.showCriticalPath && (
                <CriticalTaskStyle color={tabPreferences?.gantt_parameters?.criticalPathColor ?? 'red'} />
            )}
            {userPreferences?.gantt_style?.gridHeaderColors && (
                <GridHeaderColor
                    gridBackgroundColor={userPreferences?.gantt_style?.gridHeaderColors.gridBackgroundColor}
                    gridTextColor={userPreferences?.gantt_style?.gridHeaderColors.gridTextColor}
                />
            )}
            {tabPreferences?.gantt_simple_activity_parameters && (
                <SimpleTaskColor
                    show={tabPreferences?.gantt_simple_activity_parameters.show}
                    fontColor={tabPreferences?.gantt_simple_activity_parameters.fontColor}
                    color={tabPreferences?.gantt_simple_activity_parameters.color}
                    fontSize={tabPreferences?.gantt_simple_activity_parameters.fontSize}
                    fontStyle={tabPreferences?.gantt_simple_activity_parameters.fontStyle}
                />
            )}
            {(tabPreferences?.gantt_level_parameters ?? []).map((p, index) => (
                <TaskLevelColor
                    key={`level-style${generateString(5)}`}
                    level={index}
                    show={p.show}
                    fontColor={p.fontColor}
                    color={p.color}
                    fontSize={p.fontSize}
                    fontStyle={p.fontStyle}
                />
            ))}
            {(getArrayInListByCondition(globalNeedList, 'isLevel', false) ?? []).map((res) => (
                <TaskResourceColor
                    key={`resource-style${generateString(5)}`}
                    color={res.color ?? '#000000'}
                    name={res.id.toString()}
                />
            ))}
            {(getArrayInListByCondition(planningNeedList, 'isLevel', false) ?? []).map((res) => (
                <TaskResourceColor
                    key={`resource-style${generateString(5)}`}
                    color={res.color ?? '#000000'}
                    name={res.id.toString()}
                />
            ))}
            {(tabPreferences?.gantt_markers ?? [])
                .filter((i) => i.show)
                .map((p) => (
                    <MarkerStyle
                        key={`marker-style${generateString(5)}`}
                        class={p.markerClass}
                        fontColor={p.fontColor}
                        lineColor={p.lineColor}
                    />
                ))}
            {/* <div className="w-full flex justify-between items-center p-1">
                <ValidatePlanningModal />
            </div> */}
            <GanttToolbar isGantt />
            <div
                className="flex-grow"
                style={{
                    fontFamily: tabPreferences?.gantt_parameters?.fontFamily ?? 'inherit',
                    height: '120px',
                    minHeight: '0px',
                }}
            >
                <GanttWrapper
                    hasBaseLine={hasBaseLine}
                    marginTop={((tabPreferences.gantt_parameters?.rowHeight || 35) * 0.7) / 4}
                    id="gantt-wrapper"
                    ref={(element) => {
                        ganttContainer = element;
                    }}
                    className={`h-full w-full ${loadingGantt ? 'hidden' : ''}`}
                />
            </div>
            {/* </Spin> */}
            {multiTaskBannerVisible && multiTaskIds.length > 1 && modeSelected !== 'archive' && (
                <div
                    className="absolute flex items-center justify-between w-1/2 p-2 border border-gray-700 bg-white"
                    style={{ bottom: '-16px', left: '50%', zIndex: 10 }}
                >
                    <p>
                        {multiTaskIds.length} {t('gantt.selected_activities')}
                    </p>
                    <div className="flex items-center">
                        <MultipleActivityEdit activitiesId={multiTaskIds} />
                        {canDuplicateSelection && <MultipleActivityDuplication activitiesId={multiTaskIds} />}

                        <MultipleLinkCreation shouldUpdate={updateMultipleActivityActionComponent} />
                        {canDuplicateSelection && (
                            <Popconfirm
                                title={
                                    <span style={{ width: '230px', display: 'block' }}>
                                        {t('gantt.delete_multiple_text')}
                                    </span>
                                }
                                onCancel={() => {}}
                                onConfirm={() => handleDeleteMultipleActivity()}
                                okText={t('general.yes')}
                                cancelText={t('general.no')}
                                placement="topRight"
                            >
                                <Button danger type="text">
                                    {t('general.delete')}
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                </div>
            )}

            {selectedTask && (
                <DrawerActivity activity={selectedTask} drawerVisible={taskDrawerVisible} drawerTab={taskDrawerTab} />
            )}
            {/* {selectedTask && (
                <AddResourceModal ref={addNewResourceRef} activity={selectedTask} onResourceAdded={() => {}} />
            )} */}
        </>
    );
};
export default Gantt;
