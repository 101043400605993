import { clone } from "lodash";
import i18n from "../../i18n";
import { selectActiveTab, selectTabPreferences } from "../slices/app/tab.slice";
import { updateActivitiesDictionary } from "./activity-slice.thunk";
import createAppAsyncThunk from "./create-typed-async-thunk";
import { updateGanttTask, formatNewGanttTask } from "../../helpers/planningUtils";
import { updateActivityResourceVirtualTasks } from "./activity-calendar-slice.thunk";
import { shouldUpdateSlices } from "../utils/ActivityCalendarSliceUtils";
import { TAB_TYPE } from "../../constants/Tabs";
import { RESOURCE_VIEW_MODE } from "../../constants/Generic";
import { addGanttBufferDataToAdd, addGanttBufferDataToUpdate, addGanttBufferDataToDelete, addGanttLinkBufferDataToAdd, addGanttLinkBufferDataToUpdate, addGanttLinkBufferDataToDelete } from "../slices/app/broadcast.slice";
import { generateLinkNodes } from "../../helpers/planning";
import { incrementFilteredActivitiesCount } from "../slices/app/activity.slice";

/**
 * Handles updates to Gantt chart activity data received from broadcasts
 * @param broadcastData Object containing:
 *   - updatedData: New activity data
 *   - isTaskExist: Whether the task already exists in the Gantt chart
 *   - isNewTask: Whether this is a newly created task
 *   - id: Task identifier
 */
const handleGanttActivityDataBuffer = createAppAsyncThunk(
    'broadcast/handleGanttActivityDataBuffer',
    async (broadcastData: any, { dispatch, getState }) => {
        const { updatedData, isTaskExist, isNewTask, id } = broadcastData;
        const tabPreferences = selectTabPreferences(getState());
        const activeTab = selectActiveTab(getState());
        const isGroupement = tabPreferences?.filtered_group?.groupingType === 'custom_fields';
        
        try {
            // Handle activity data update
            if (updatedData) {
                await dispatch(updateActivitiesDictionary(updatedData.id, clone(updatedData)));

                // Handle new task creation
                if (!isTaskExist && !isGroupement) {
                    const task = formatNewGanttTask(updatedData, tabPreferences);
                    dispatch(incrementFilteredActivitiesCount());
                    dispatch(addGanttBufferDataToAdd(task));
                } 
                // Handle existing task update
                else if (isTaskExist && !isNewTask) {
                    const oldData = (window as any).ganttInstance.getTaskByServerId(id);
                    const taskData = { ...updatedData };

                    if (isGroupement) {
                        taskData.type = oldData.type;
                        taskData.parent = oldData.parent;
                    } else {
                        taskData.parent = (window as any).ganttInstance.getTaskByServerId(
                            updatedData.activityParentId
                        )?.id || 0;
                    }
                    const task = updateGanttTask(taskData, tabPreferences);
                    dispatch(addGanttBufferDataToUpdate(task));
                }
            } 
            // Handle task deletion
            else if (isTaskExist) {
                dispatch(addGanttBufferDataToDelete(id));
            }

            // Update resource histogram if needed
            const showHistogram =
                activeTab?.tabType === TAB_TYPE.GANTT
                    ? tabPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value
                    : activeTab?.tabType === TAB_TYPE.RESOURCE;
            if (
                broadcastData &&
                !broadcastData.isNewTask &&
                shouldUpdateSlices(broadcastData.diffFields) &&
                showHistogram
            ) {
                console.log('XXXX updateActivityResourceVirtualTasks');
                await dispatch(
                    updateActivityResourceVirtualTasks({
                        taskId: broadcastData.id,
                        updatedData: broadcastData.updatedData,
                    })
                );
            }
        } catch (error) {
            console.error('Error processing update:', error);
        }
    }
)

/**
 * Handles updates to Gantt chart dependency links received from broadcasts
 * @param broadcastData Object containing:
 *   - updatedData: New link data
 *   - isLinkExists: Whether the link already exists in the Gantt chart
 *   - id: Link identifier
 */
export const handleGanttLinkDataBuffer = createAppAsyncThunk(
    'broadcast/handleGanttLinkDataBuffer',
    async (broadcastData: any, { dispatch }) => {
        const { updatedData, isLinkExists, id } = broadcastData;
        // new link from other users
        if (!isLinkExists && updatedData) {
            const flattenActivities = (window as any).ganttInstance.getTaskByTime();
            const generatedNewLinks = generateLinkNodes([updatedData], flattenActivities);
            console.log('generatedNewLinks', generatedNewLinks);
            (window as any).ganttDataProcessor = false;
            dispatch(addGanttLinkBufferDataToAdd(generatedNewLinks));
            (window as any).ganttDataProcessor = true;
        }
        // Update link in Gantt if updated data
        if (updatedData && isLinkExists) {
            const flattenActivities = (window as any).ganttInstance.getTaskByTime();
            // Format the link data for Gantt
            const generatedLinks = generateLinkNodes([updatedData], flattenActivities);
            dispatch(addGanttLinkBufferDataToUpdate(generatedLinks));
        }
        // Remove link from Gantt if no updated data
        if (!updatedData && isLinkExists) {
            dispatch(addGanttLinkBufferDataToDelete(id));
        }
    }
)

export default handleGanttActivityDataBuffer;
