import moment from 'moment';
import { clone } from 'lodash';
import FolderService from '../services/folder.service';
import ActivityService from '../services/activity.service';
import { JOB_FIELD } from '../constants/Jobs';
import { generateString } from './string-helper';
import { GANTT_CUSTOM_PROJECT } from '../components/plannings/gantt_config';
import {selectTabPreferences} from "../redux/slices/app/tab.slice";
import store from "../redux/store";

export const ganttDatetimeFormat = 'DD-MM-YYYY HH:mm:ss';
// if (window?.localStorage) {

//     moment.defineLocale(window.localStorage.getItem('lng') || 'fr', {
//         week: {
//             dow: 1, // First day of week is Monday
//             doy: 7, // First week of year must contain 1 January (7 + 1 - 1)
//         },
//     });
// } else {
//     moment.defineLocale('fr', {
//         week: {
//             dow: 1, // First day of week is Monday
//             doy: 7, // First week of year must contain 1 January (7 + 1 - 1)
//         },
//     })
// }
export const dateToTimestamp = (date, dateFormat = null, isUtc = true) =>
    moment(date, dateFormat).utc(isUtc).startOf('minute').valueOf();
export const dateToJsDate = (date, dateFormat = null, isUtc = true) =>
    moment(date ? date + new Date(date).getTimezoneOffset() * 60000 : date, dateFormat)
        .utc(isUtc)
        .startOf('minute')
        .toDate();
export const dateToUTCDate = (date) =>
    moment(date ? date + new Date(date).getTimezoneOffset() * 60000 : date)
        // .utc(true)
        .startOf('minute')
        .valueOf();

export const clearExplorerSelected = (list, targetDepth, depth = 0) => {
    const res = list.map((item) => {
        if (depth >= targetDepth) {
            return { ...item, selected: false };
        }

        return {
            ...item,
            childFolders: clearExplorerSelected(item.childFolders || [], targetDepth, depth + 1),
            plannings: clearExplorerSelected(item.plannings || [], targetDepth, depth + 1),
        };
    });
    return res;
};

export const setExplorerItemSelected = (list, itemId, childFolders, plannings) => {
    const res2 = list.map((item) => {
        if (item.id === itemId) {
            return { ...item, selected: true, childFolders: childFolders || [], plannings: plannings || [] };
        }

        if (item.childFolders) {
            return {
                ...item,
                childFolders: setExplorerItemSelected(item.childFolders || [], itemId, childFolders, plannings || []),
                plannings: setExplorerItemSelected(item.plannings || [], itemId, [], plannings || []),
            };
        }

        return { ...item, selected: false };
    });
    return res2;
};

export const buildParentDirectoryStructure = async (itemId, openedFolders) => {
    if (!openedFolders) {
        // eslint-disable-next-line no-param-reassign
        openedFolders = [];
    }

    return FolderService.showFolder(itemId)
        .then((result) => {
            openedFolders.unshift(result);

            if (result.parentId) {
                return buildParentDirectoryStructure(result.parentId, openedFolders);
            }

            return openedFolders;
        })
        .catch(() =>
            // Folder not found. Reset navigation to root
            []
        );
};

export const getTaskType = (activity) => {
    if (activity.startDate === activity.endDate) {
        return 'milestone';
    }
    return activity.subActivitiesId.length > 0 ? GANTT_CUSTOM_PROJECT : 'task';
};

export const ACTIVITY_STATE = Object.freeze({
    VALID: 'VALID',
    UNVALID: 'UNVALID',
    LOCKED: 'LOCKED',
    IN_CONFLICT: 'IN_CONFLICT',
});

export const USER_ACCESS = Object.freeze({
    NONE: 'NONE',
    READ: 'READ',
    READWRITE: 'WRITE',
    OWNER: 'OWNER',
    ADMIN: 'ADMIN',
    FULLACCESS: 'FULLACCESS',
});

export const formatGanttTask = (activity, overrides = {}) => ({
    id: generateString(7),
    serverId: activity.id, // used for server operations
    identity: activity.identity,
    description: activity.description,
    start_date: dateToJsDate(activity.startDate, null, false),
    planned_start: activity.baseLineStartDate,
    startDate: activity.startDate,
    end_date: dateToJsDate(activity.endDate, null, false),
    planned_end: activity.baseLineEndDate,
    baseline_progress: activity.baseLineProgress,
    endDate: activity.endDate,
    freeMargin: activity.freeMargin,
    free_Margin: dateToJsDate(activity.freeMargin, null, false),
    totalMargin: activity.totalMargin,
    total_Margin: dateToJsDate(activity.totalMargin, null, false),
    durationApi: activity.taskDuration,
    roundedDuration: activity.taskDuration ? Number(activity.taskDuration).toFixed(1) : 0,
    calendarId: activity.calendarId,
    dayDefinitionId: activity.dayDefinitionId,
    text: activity.name,
    note: null,
    jobId: activity.job,
    type: getTaskType(activity),
    taskType: getTaskType(activity),
    progress: (activity.avancement ?? 0) / 100,
    avancement: activity.avancement || 0,
    parent: activity.activityParentId || 0,
    parentId: activity.activityParentId || 0,
    children: activity.subActivitiesId,
    realChildren: activity.subActivitiesId,
    isResource: false,
    sortorder: 0,
    open: 1,
    customFields: activity.unifiedfields,
    status: activity.activityState,
    locked: activity.activityState === ACTIVITY_STATE.LOCKED,
    data_api: { ...activity, subActivities: [] }, // TODO change this, use flattenActivities state
    predecessorLinksId: activity.predecessorLinksId,
    successorLinksId: activity.successorLinksId,
    quantity: activity.quantity,
    quantityUnit: activity.quantityUnit,
    champPMAutoId: activity.champPMAutoId,
    yield: activity.yield,
    colors: activity.colors,
    // add customField individually for inline edit
    ...activity.unifiedfields?.reduce((acc, val) => {
        const currentObject = acc;
        currentObject[`customField#${val.id}`] = val.name === JOB_FIELD ? val.value : val.value[0]; // TODO change
        return currentObject;
    }, {}),

    ...overrides,
});

export const fillGanttNodes = (flattenActivities, filteredActivities) =>
    filteredActivities.map((filteredActivity) => {
        if (filteredActivity.virtual) {
            return formatGanttTask(
                {
                    ...filteredActivity,
                    subActivitiesId: filteredActivity.subActivities.map((subActivity) => subActivity.id),
                    unifiedfields: [],
                },
                {
                    id: (Math.random() + 1).toString(36).substring(7),
                    subActivities:
                        filteredActivity?.subActivities?.length > 0
                            ? fillGanttNodes(flattenActivities, filteredActivity.subActivities)
                            : [],
                    colors: filteredActivity?.colors || [],
                    virtual: true,
                }
            );
        }
        // console.log('fillNodes activity', {
        //     ...flattenActivities[filteredActivity.id],
        //     subActivitiesId: (filteredActivity?.subActivities || []).map((subActivity) => subActivity.id),
        // });
        // console.log('fillNodes override', {
        //     ...filteredActivity,
        //     subActivities:
        //         filteredActivity?.subActivities?.length > 0
        //             ? fillGanttNodes(flattenActivities, filteredActivity.subActivities)
        //             : [],
        // })
        const filteredInfo = clone(filteredActivity);
        delete filteredInfo.id;
        return formatGanttTask(
            {
                ...flattenActivities[filteredActivity.id],
                subActivitiesId: (filteredActivity?.subActivities || []).map((subActivity) => subActivity.id),
            },
            {
                ...filteredInfo,
                realChildren: flattenActivities[filteredActivity.id]?.subActivitiesId ?? [],
                subActivities:
                    filteredActivity?.subActivities?.length > 0
                        ? fillGanttNodes(flattenActivities, filteredActivity.subActivities)
                        : [],
            }
        );
    });


export const flattenGanttNodesOpt = (filteredActivities, filteredParentActivity = null) => 
    filteredActivities.reduce((result, currentActivity) => {
        const activity = {...currentActivity};
        activity.parent = filteredParentActivity?.id || null;
        delete activity.subActivities;
        result.push(activity);
        
        if (currentActivity.subActivities) {
            result.push(...flattenGanttNodesOpt(currentActivity.subActivities, currentActivity));
        }
        
        return result;
    }, []);
    

export const generateGroupedGanttNodes = async (
    flattenActivities,
    rootActivityId,
    planningColor,
    simpleActivityOnly,
    sortOptions,
    filters
) =>
    fillGanttNodes(
        flattenActivities,
        await ActivityService.createFilteredGroup(
            rootActivityId,
            planningColor?.id,
            simpleActivityOnly,
            sortOptions,
            filters
        )
    );

export const generateFilteredGanttNodes = async (flattenActivities, rootActivityId, planningColor, filters) => {
    const activity = flattenActivities[rootActivityId];
    let activities = [];

    if (activity.subActivitiesId.length > 0) {
        activities = [await ActivityService.applyFilter(rootActivityId, planningColor?.id, filters)];
    } else {
        activities = [activity];
    }

    return fillGanttNodes(flattenActivities, activities);
};

export const generateLinkNodes = (links, flattenActivities) => {
    const tabPreferences = selectTabPreferences(store.getState());
    const isShowCriticalPath = tabPreferences?.gantt_parameters?.showCriticalPath;
    const criticalPathColor = tabPreferences?.gantt_parameters?.criticalPathColor;
    const generatedLinks = [];
    links.forEach((link) => {
        const sourceActivities = flattenActivities.filter((i) => i.serverId === link.activityPredecessorId);
        const targetActivities = flattenActivities.filter((i) => i.serverId === link.activitySuccessorId);

        sourceActivities.forEach((source) =>
            targetActivities.forEach((target) => {
                const isCriticLinks = isShowCriticalPath && source.totalMargin <= source.endDate && target.totalMargin <= target.endDate;
                const linkObject = {
                    id: generateString(7),
                    serverId: link.id,
                    source: source.id, // fake id
                    target: target.id, // fake id
                    sourceId: source.serverId,
                    targetId: target.serverId,
                    type: { FD: 0, DD: 1, FF: 2, DF: 3 }[link.type],
                    color: isCriticLinks ? criticalPathColor : link.category.color,
                    dataAPI: link,
                };
                generatedLinks.push(linkObject);
            })
        );
    });
    return generatedLinks;
};

export const generateLinks = (sourceActivityId, targetActivityId, linkData) => {
    const generatedLinks = [];
    const sourceActivities = window.ganttInstance.getTaskBy('serverId', sourceActivityId);
    const targetActivities = window.ganttInstance.getTaskBy('serverId', targetActivityId);
    sourceActivities.forEach((source) =>
        targetActivities.forEach((target) => {
            const linkObject = {
                id: generateString(7),
                serverId: linkData.id,
                source: source.id, // fake id
                target: target.id, // fake id
                sourceId: source.serverId,
                targetId: target.serverId,
                type: { FD: 0, DD: 1, FF: 2, DF: 3 }[linkData.type],
                color: linkData.category.color,
                dataAPI: linkData,
            };
            generatedLinks.push(linkObject);
        })
    );
    return generatedLinks;
};

export const getPlanningAccess = (planningData, currentUser) => {
    let userAccess = USER_ACCESS.NONE;
    if (planningData.ownerId === currentUser.userId) {
        userAccess = USER_ACCESS.OWNER;
        return userAccess;
    }
    if (currentUser.isAdmin) {
        userAccess = USER_ACCESS.ADMIN;
        return userAccess;
    }
    (planningData.listPermission || []).forEach((element) => {
        if (element.team.userIds.indexOf(currentUser.userId) !== -1 && userAccess !== USER_ACCESS.READWRITE) {
            userAccess = element.access;
        }
        if (element.team.single && element.team.userIds.indexOf(currentUser.userId) !== -1) {
            userAccess = element.access;
        }
    });
    
    return userAccess;
};

export const formatResourceTask = (parent, slices, text, startDate, endDate, parentServerId, isResourceSummary = false) => ({
    id: generateString(6),
    text,
    parent,
    start_date: dateToJsDate(startDate, null, false),
    end_date: dateToJsDate(endDate, null, false),
    type: 'task',
    isResource: true,
    virtual: true,
    slices,
    isResourceSummary,
    parentServerId
});

export const batchUpdateGanttTasks = ({toAdd = [], toUpdate = [], toDelete = []}) => {
   if (window.ganttInstance) {  
        window.ganttInstance.batchUpdate(() => {
            toAdd.forEach((task) => window.ganttInstance.addTask({...task}));
            toUpdate.forEach((task) => window.ganttInstance.updateTaskByServerId(task.serverId, task));
            toDelete.forEach((id) => window.ganttInstance.deleteTaskByServerId(id));
        });
    }
}

export const batchUpdateGanttLinks = ({toAdd = [], toUpdate = [], toDelete = []}) => {
    if (window.ganttInstance) {  
        window.ganttDataProcessor = false;
        console.log(window.ganttDataProcessor);
        window.ganttInstance.batchUpdate(() => {
            toAdd.forEach((link) => window.ganttInstance.addLink(link));
            toUpdate.forEach((link) => window.ganttInstance.updateLinkByServerId(link.serverId, link));
            toDelete.forEach((id) => window.ganttInstance.deleteLinkByServerId(id));
        });
        window.ganttDataProcessor = true;
    }
}

export const focusInlineEditor = () => {
    if (window.ganttInstance && window.ganttInstance.ext.inlineEditors.isVisible()) {
        window.ganttInstance.ext.inlineEditors.focus();
    }
}
